import Ratings from 'components/Ratings' // we need this to make JSX compile
import Copyright from 'components/Copyright'
import CompanyInformationCard from 'components/cards/CompanyInformationCard'
import Strings from 'project/localisation'
import ActiveLink from './ActiveLink'
import { ReactFC } from 'common/types/ReactFC'
import {
  companyLinks,
  customerCareLinks,
  enterpriseSystemLinks,
  legalLinks,
  officeLinks,
  productLinks,
  serviceLinks,
} from './Header'
import React from 'react'
import Accordion from 'components/Accordion'
import Link from 'next/link'
import Heading from 'components/Heading'
import Card from 'components/cards/base/Card'
import Button from 'components/forms/Button'
import { TLinkGroup } from 'common/cms-types'
import useWindowSize from 'components/util/useWindowSize'
import { useRouter } from 'next/router'
import imageResize from 'project/image-proxy-web'
import { Project } from 'common/project'

function mobileFooterLinks(addAllProducts: boolean) {
  return [
    {
      links: productLinks(addAllProducts),
      title: Strings.products,
    },
    {
      links: serviceLinks(),
      title: Strings.services,
    },
    {
      links: enterpriseSystemLinks(),
      title: Strings.enterpriseSystems,
    },
    {
      links: officeLinks(),
      title: Strings.officeSpace,
    },
    {
      links: companyLinks(),
      title: Strings.company,
    },
    {
      links: customerCareLinks(),
      title: Strings.customerCare,
    },
    {
      links: legalLinks(),
      title: Strings.legalInformation,
    },
  ] as TLinkGroup[]
}

function _footerLinks() {
  return [
    [
      {
        links: productLinks(),
        title: Strings.products,
      },
      {
        links: serviceLinks(),
        title: Strings.services,
      },
    ],
    [
      {
        links: enterpriseSystemLinks(),
        title: Strings.enterpriseSystems,
      },
    ],
    [
      {
        links: officeLinks(),
        title: Strings.officeSpace,
      },
    ],
    [
      {
        links: companyLinks(),
        title: Strings.company,
      },
    ],

    [
      {
        links: customerCareLinks(),
        title: Strings.customerCare,
      },
    ],
    [
      {
        links: legalLinks(),
        title: Strings.legalInformation,
      },
    ],
  ] as TLinkGroup[][]
}

type FooterType = {
  hideFooterCard?: boolean
}

const Footer: ReactFC<FooterType> = ({ hideFooterCard }) => {
  const footerLinks = _footerLinks()
  const size = useWindowSize()
  const router = useRouter()
  const currentPath = router.asPath.replace(/\//g, '')

  return (
    <footer className={hideFooterCard ? 'pt-5' : ''}>
      <div className='d-flex justify-content-center'>
        {!hideFooterCard && (
          <div className='footer-card-container'>
            <Card className='footer-card justify-content-center'>
              <div className='row align-items-center'>
                <div className='col-lg-3 footer-card__image p-0 d-flex justify-content-center'>
                  <img
                    loading='lazy'
                    className='img-fluid mx-auto me-lg-n3'
                    width={302}
                    height={418}
                    alt={'Hoxton Mix Illustration'}
                    src={imageResize(
                      require('public/images/hero/homepage-collect-plan-desktop.png'),
                      302,
                      418,
                    )}
                  />
                </div>
                <div className='col-lg-9 footer-card__text ps-lg-6 pe-lg-5 px-5'>
                  <Heading className='h4 mb-lg-2 mb-3' size={3}>
                    {Strings.weHaveHelped}
                  </Heading>
                  <Heading className='h4 text-muted mb-5 mb-lg-0' size={3}>
                    {Strings.letUsHelp}
                  </Heading>
                  {currentPath !== 'checkout' &&
                    currentPath !== 'thanks' &&
                    currentPath !== 'payment' &&
                    currentPath !== 'konnect' && (
                      <div className='d-flex justify-content-lg-end justify-content-center'>
                        <Link
                          className='a-unstyled'
                          href={'/virtual-office-london-old-street'}
                        >
                          <Button
                            className={'px-lg-4 px-7'}
                            size='medium'
                            theme='secondary'
                          >
                            {Strings.viewPlans}
                          </Button>
                        </Link>
                      </div>
                    )}
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
      {!size.isLgOrHigher && (
        <div className='footer-container'>
          <Accordion
            chevronColour='#6E6D72'
            items={mobileFooterLinks().map((v) => ({
              body: (
                <>
                  {v.links.map((link) => (
                    <div key={link.title}>
                      <Link className='a-unstyled' href={link.href}>
                        <span className='link cursor-pointer'>
                          {link.title}
                        </span>
                      </Link>
                    </div>
                  ))}
                </>
              ),
              title: `${v.title}`,
            }))}
          />
          <CompanyInformationCard className='mt-sm-3 mt-4' />
          <hr />
          <Ratings />
          <hr />
          <Copyright />
          <div className='d-flex justify-content-center'>
            <Link target={'_blank'} href={Project.appStore}>
              <img
                loading='lazy'
                alt={'Apple Icon'}
                height={40}
                src={imageResize(require('public/images/app/Apple.png'))}
                className={'me-3'}
              />
            </Link>
            <Link target={'_blank'} href={Project.playStore}>
              <img
                loading='lazy'
                alt={'Google Icon'}
                height={40}
                src={imageResize(require('public/images/app/Google.png'))}
              />
            </Link>
          </div>
        </div>
      )}
      {size.isLgOrHigher && (
        <div className='footer-container'>
          <div className='container-fluid'>
            <div className='row footer__links'>
              {footerLinks.map((data, i) => (
                <div className='col-md-2' key={i}>
                  {data.map((list) => (
                    <>
                      <p className='h6 mt-5'>{list.title}</p>
                      <div className='list-footer' key={list.title}>
                        {list.links.map((data, i) => {
                          const isAbsolute = data.href.includes('http')
                          const Tag = isAbsolute ? `a` : ActiveLink
                          return (
                            <Tag
                              href={data.href}
                              className='d-block nav-link'
                              rel='noreferrer'
                              prefetch={false}
                              key={i}
                            >
                              <>{data.title}</>
                            </Tag>
                          )
                        })}
                      </div>
                    </>
                  ))}
                  {footerLinks.length - 1 === i && (
                    <div className='mt-5'>
                      <CompanyInformationCard />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className='col-md-8 offset-md-2'>
            <Ratings />
            <hr />
            <Copyright />
            <div className='d-flex justify-content-center'>
              <Link target={'_blank'} href={Project.appStore}>
                <img
                  loading='lazy'
                  alt={'Apple Icon'}
                  height={40}
                  src={imageResize(require('public/images/app/Apple.png'))}
                  className={'me-3'}
                />
              </Link>
              <Link target={'_blank'} href={Project.playStore}>
                <img
                  loading='lazy'
                  alt={'Google Icon'}
                  height={40}
                  src={imageResize(require('public/images/app/Google.png'))}
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </footer>
  )
}

export default Footer
