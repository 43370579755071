import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'
import { Format } from 'common/utils/format'

export const tideCashBackAmount = Format.money(7500).replace('.00', '')
const tideMinimumBalance = Format.money(100).replace('.00', '')

const defaultContent = {
  anOverviewOfTidesFeatures: 'An overview of Tide’s account features:',
  anOverviewOfTidesFeaturesDescription: `<p class="mb-lg-5 mb-3"><strong class="color-midnight2">Free</strong> signup and no monthly or annual fees</p>
<p class="mb-lg-5 mb-3"><strong class="color-midnight2">Free</strong> Mastercard usage, at home and abroad</p>
<p class="mb-lg-5 mb-3"><strong class="color-midnight2">Free</strong> transfers to Tide accounts</p>
<p class="mb-lg-5 mb-3">Order up to 35 additional Mastercards for team members</p>
<p class="mb-lg-5 mb-3">Make VAT returns a breeze with easy expense management - upload receipts, auto-match them to transactions, add notes and categories</p>
<p class="mb-lg-5 mb-3">In-app support available 24 hours a day, every day</p>
<p class="mb-lg-5 mb-3">Mobile first but not mobile only - manage your account in a way that works for you, whether it’s in the palm of your hand, or from your computer</p>
<p class="mb-0">Access direct feeds with Xero, FreeAgent, Sage, Quickbooks, and Kashflow to help you to manage your accounts</p>`,
  forTheOfferTerms: 'for the Offer T&C’s',
  fullOfferTerms: 'full offer T&Cs',
  helpingYouSaveTimeAndMoney:
    'Helping you save time <br class="d-sm-none"/> and money.',
  helpingYouSaveTimeAndMoneyDescription:
    'Hoxton Mix is delighted to introduce Tide as their exclusive business banking partner <br class="d-xxl-block d-none"/> to help save you time and money managing your business admin.',
  heroSubtitle: `Open a free business bank account in minutes and <br class="d-md-block d-none"/> get ${tideCashBackAmount} cashback with Tide!`,
  heroTitle: 'A free business bank <br/> account in minutes',
  offerTermsAndConditions: 'Offer T&C’s',
  offerTermsAndConditionsDescription: `<p>
To be eligible to receive the Hoxton Mix promotional offer (the “Offer”), applicants must first successfully open a sole trader or limited company business current account with Tide. Account opening eligibility criteria and customary know your customer checks apply.
</p><p>
The Offer includes:
</p><p>
a ${tideCashBackAmount} cashback reward – this is subject to Minimum Balance requirements (see paras. 5-6 below); and one year of free transfers to any UK accounts.
</p><p>
The Offer is only valid for new accounts and applicants who haven’t previously opened an account with Tide.
</p><p>
To activate the Offer, the applicant must use the referral code: HOXTON. The applicant is required to either enter the referral code while applying for a Tide account via the Tide mobile app or the Tide website or simply let us know through the in-app chat within 1 week of successfully opening the account (e.g. if an applicant successfully opens a new Tide account on Monday, the applicant will have until and including the following Monday to give us the referral code via the in-app chat).
</p><p>
The Offer is limited to one ${tideCashBackAmount} cashback reward per applicant. To receive the reward, the applicant must credit the new Tide account with a minimum of ${tideMinimumBalance} (the “Minimum Balance”).
</p><p>
The Minimum Balance must be credited by the applicant within three months of the account opening date.
</p><p>
The reward will be paid into the applicant’s new Tide account. It can take up to 8 weeks from the date the applicant credits the account with the Minimum Balance for the reward to be validated and paid.
</p><p>
We reserve the right to modify these terms and conditions or withdraw this Offer at any time.
</p><p>
This Offer cannot be used in conjunction with any other Tide offer or promotion.
</p>`,
  openAnAccount: 'Open an account',
  ourExclusiveBusinessBankingPartner: 'Our exclusive business banking partner',
  seoBusinessBankingDescription:
    'Open a free business bank account in minutes and get ${tideCashBackAmount} cashback with Tide!',
  seoBusinessBankingTitle:
    'A free business bank account in minutes | Hoxton Mix',
  tideOverview: `<p class="mb-lg-5 mb-3"><strong>*Tide is not a bank, but a business banking platform and the leading digital challenger in business banking services. See the {0} for further details. Transfers to non-Tide accounts are free for one year, <br class="d-sm-block d-none"/>20p per transfer thereafter.
</strong></p>
  <p class="mb-lg-5 mb-3 pe-1">To benefit from the ${tideCashBackAmount} cashback reward, make sure to credit your new Tide account with a minimum ${tideMinimumBalance} within 3 months of <br/> account opening. Tide is not a bank, but a business banking platform and the leading digital challenger in business banking services.
  </p><p class="mb-lg-5 mb-3 pe-lg-0 pe-2">
  Tide offers bank accounts provided by ClearBank. ClearBank Ltd is Authorised by the Prudential Regulation Authority and regulated <br class="d-sm-block d-none"/> by the Financial Conduct Authority and the Prudential Regulation Authority (Financial Services Register number: 754568). Registered Address: 4th Floor, Prologue Works, 25 Marsh Street, Bristol, England, BS1 4AX.
</p>`,
  tideTrusted:
    'Trusted by over 400,000 UK businesses and one of the UK’s fastest-growing digital business <br class="d-sm-none"/> banking platforms',
  tideTrustedDescription: `<p class="mb-lg-5 mb-3">
<a href="https://www.tide.co/partners/hoxton-mix/"  class="color-pink4 cursor-pointer">Tide</a>  offers powerful and simple free business bank accounts that can be opened in minutes, managed on your mobile and are FSCS-protected. With no credit checks required, and no monthly or annual fees, why not give <a href="https://www.tide.co/partners/hoxton-mix/"  class="color-pink4 cursor-pointer">Tide</a>  a try alongside your existing account? Spend less time on admin and more time running your business.
</p><p>Hoxton Mix members are eligible for an exclusive offer from Tide - <strong class="color-midnight2">${tideCashBackAmount} cashback</strong> and one year of <strong class="color-midnight2">free</strong> UK transfers!* Open an account using the code <strong class="color-midnight2">HOXTON</strong> to claim this offer.
</p>`,
}
export const businessBankingStrings = getApi().makeStrings({
  en: defaultContent,
  es: blobbyStrings(defaultContent),
})
