import { Format } from 'common/utils/format'
import { LocalizedStringsMethods } from './types/string-types'

export type LocalizedStrings = LocalizedStringsMethods

type StringsSingleton = {
  instance: LocalizedStrings | undefined
}

const strings: StringsSingleton = {
  instance: undefined,
}

const getStrings: () => LocalizedStrings = () => {
  if (!strings.instance) {
    throw new Error(
      'Strings is not initialized. Make sure Web or Mobile sets strings.instance',
    )
  }
  return strings.instance
}
export type LanguageContent = typeof defaultContent

const setStrings = (stringsParam: LocalizedStrings) => {
  strings.instance = stringsParam
}

export function blobbyStrings(strings: Record<string, string>) {
  // @ts-ignore
  const blobby: LanguageContent = {}
  Object.keys(strings).map((key) => {
    // @ts-ignore
    const val = strings[key]
    const words = val.split(' ')
    const newWordsBlobby = words.map((word: string, index: number) => {
      const arr = ['eeeee', 'blob', 'blobby', 'wuueeeeh']
      const idx = Math.floor(Math.random() * arr.length)
      if (word.indexOf('{') !== -1) {
        // reserve params
        return word
      }
      return arr[idx]
    })
    blobby[key as keyof LanguageContent] = `${newWordsBlobby.join(' ').trim()}`
  })
  return blobby
}

const priceString = {
  lowestFairPrice: Format.money(49),
  newRegistrationCashbackPrice: Format.money(1000).replace('.00', ''),
  newRegistrationPrice: Format.money(1000).replace('.00', ''),
}

const defaultContent = {
  aboutUs: 'About Us',
  add: 'Add',
  addons: 'Add-ons',
  address: 'Address',
  affordableWorkspace: 'Affordable Workspace',
  allVirtualOfficePlans: 'All Virtual Office Plans',
  becomeAPartner: 'Become a partner',
  blog: 'Blog',
  businessSupport: 'Business Support',
  businessType: 'Business type',
  buy: 'Buy',
  characterRemaining: '{0} character remaining',
  charactersRemaining: '{0} characters remaining',
  chatWithOneOfOurTeam: 'Chat with one of our team members',
  checkOutOurOtherCommunityPages: 'Check out our other community pages',
  checkout: 'Checkout',
  choosingTheRightPlan:
    '<span class="d-block">Choosing the right plan</span> <span class="d-block">for your business.</span>',
  choosingTheRightPlanDescription: `Get a central London address to use as your registered office address from just ${priceString.lowestFairPrice} per day.`,
  choosingTheRightPlanForMobile:
    '<span class="d-block">Choosing the right</span> <span class="d-block">plan for your business.</span>',
  clickHere: 'Click here',
  company: 'Company',
  companyFormation: 'Company Formation',
  companyInformation: 'Need Help?',
  companyNo: 'Company no.',
  companyNumber: 'Company number',
  companyNumberPlaceholder: 'Type Company number here',
  contactInformation: 'Contact information',
  contactUs: 'Contact Us',
  cookies: 'Cookies',
  copyright:
    'Copyright © {0} {name} The Hoxton Mix Limited. All rights reserved.',
  countryOfIncorporation: 'Country of incorporation',
  coworking: 'Coworking',
  customerCare: 'Customer Care',
  directions: 'Directions',
  download: 'Download',
  downloadTime: 'Typically takes 1–2 seconds. {0} KB',
  emailAddress: 'Email Address',
  enquireNow: 'Enquire now',
  enterpriseSystems: 'Partners',
  expandAll: 'Expand All',
  experiencedTeam:
    'Our team is highly experienced and have helped thousands of businesses to thrive.',
  faq: 'FAQ',
  faqDescription: 'A cheap business address to impress without the costs',
  findOutMore: 'Find out more',
  firstClassCustomerSupport: 'First class customer <br/> service and support',
  firstClassCustomerSupportDescription:
    'Our dedicated team are on hand <br/> to help with any queries',
  firstName: 'First Name',
  freeBusinessBanking: 'Free Business Banking',
  freeCompanyFormations: 'Free Limited Company Formations With Crunch.',
  freeCompanyFormationsDescription: 'When you buy a Virtual Office.',
  frequentlyAskedQuestions: 'Frequently Asked Questions',
  getAPrimeBusinessAddress:
    'Get a Prime London business address <br/> 10 Minute Setup + <strong>NO Postage Fees!</strong>',
  getInTouch: 'Get in touch',
  getInTouchForPlansTitle:
    '<span class="d-block">Get in touch with our friendly</span> team to find out more.',
  getInTouchForPlansTitleForMobile:
    '<span class="d-block">Get in touch with our</span> <span class="d-block">friendly team to</span> find out more.',
  getInTouchTitleForVirtualOfficePhone:
    '<span class="d-block">Get in touch to discuss setting up</span> a virtual office phone today.',
  gettingYouSetup:
    '<span class="d-sm-inline d-block">Getting you set up</span> is easy as',
  go: 'GO',
  hello: 'Hello',
  helpAndAdvice: 'Help & Advice',
  helpBusinessesGrow:
    '<span class="d-sm-inline d-block">Grow your business</span> with Hoxton Mix',
  helpBusinessesGrowDescription:
    'Connect with the right people, products and services to help your business grow.',
  heroSubtitle:
    '<span class="d-sm-inline d-none">A cheap business address with 10-minute Setup & </span> <span class="d-sm-none">Prime London business address with 10 Minute Setup + </span><strong>NO postage fees!</strong></span>',
  heroTitle:
    '<span class="d-sm-block">Want an impressive<span/> <span class="d-sm-block">London business address?</span>',
  home: 'Home',
  howItWorks: 'How it works',
  hoxtonMixApp: 'Mobile Apps',
  icoNumber: 'ICO no.',
  idealFor:
    '<span class="d-xxl-block d-lg-inline d-block">Ideal for businesses who want a prime </span> <span class="d-xxl-block d-lg-inline d-block">London business address, without </span> <span class="d-xxl-block d-lg-inline d-block">the expensive overheads.</span>',
  industries: 'Industries',
  keepUpToDate: 'Keep up to date with what’s going on in the mix…',
  keyFeatures: 'Key features',
  konnect: 'Konnect',
  kycRequirements: 'KYC Requirements',
  lastName: 'Last Name',
  learnMore: 'Learn more',
  legalInformation: 'Legal Information',
  legalName: 'Legal name',
  legalNamePlaceholder: 'Type legal name here',
  letUsHelp: 'Let us help you get started on your business journey.',
  login: 'Login',
  logo: 'Logo',
  londonsTechnologyHub: 'London’s technology hub',
  mailScanningAndForwarding: 'Mail scanning <br/> and forwarding',
  mailScanningAndForwardingDescription:
    'We forward on all your customers <br/> post within one business day',
  mailStorage: 'Mail <br class="d-lg-block d-none"/> storage',
  mailStorageDescription: 'We keep it safe & sound at our <br/> Secure office',
  mailroom: 'Mailroom',
  mailroomOutsourcing: 'Mailroom Outsourcing',
  marketPlace: 'Marketplace',
  meetingRooms: 'Meeting Rooms',
  membership: 'Membership',
  minutesOrLess: 'minutes or less',
  minutesOrLess2: 'minutes<br/>or less',
  mlrNumber: 'MLR no.',
  needHelp: 'Need help?',
  newsAndEventsBlog: 'News & Events Blog.',
  newsAndEventsBlogDescription:
    "Keep up to date with what's going on in the mix...",
  next: 'Next',
  noSetupFees: 'No\nsetup\nfees',
  noSetupOrHandlingFees: 'No\nsetup or\nhandling\nfees!',
  notACustomer: 'Not a Virtual Office customer? Why not sign-up today.',
  offers: 'Offers',
  officeSpace: 'Office Space',
  openingHours: 'Opening hours',
  openingHoursDescription: 'Monday - Friday 9am - 18.00pm',
  ourAddress: 'Our Address',
  ourMeetingRoomsAreLocated:
    'Our meeting rooms are located at our Paul Street office,<br class="d-lg-block d-none"/> and it’s just a few minutes walk from Old Street tube station…',
  outreach: 'Outreach',
  page: 'Page',
  partnerAPI: 'Partner API',
  partnerships: 'Partnerships',
  paulStreet: 'Paul Street',
  payment: 'Payment',
  perDay: 'Per day',
  perHourPlusVAT: 'per hour + VAT',
  perMonth: 'Per Month',
  perMonthPlusVAT: 'Per Month + VAT',
  perMonthPlusVATLower: 'per month + VAT',
  perYear: 'Per Year',
  perYearPlusVAT: 'Per Year + VAT',
  phone: 'Phone',
  pleaseSelect: 'Select an Option...',
  plusVAT: '+ VAT',
  popularDeals: 'Popular Deals',
  poweredByHoxtonMix: 'powered by Hoxton Mix',
  previous: 'Previous',
  prices: 'Prices',
  privacy: 'Privacy',
  privacyPolicy: 'Privacy Policy',
  privateOffices: 'Private Offices',
  products: 'Products',
  remove: 'Remove',
  removeFromBag: 'Remove',
  search: 'Search',
  selectYourProduct: 'Please select your product below',
  services: 'Services',
  showAll: 'Show All',
  showMore: 'Show more',
  siliconRoundabout: 'Silicon Roundabout',
  socialImpact: 'Social Impact',
  support: 'Support',
  termsAndConditions: 'Terms & Conditions',
  termsOfUse: 'Terms of Use',
  termsofService: 'Terms of Service',
  toggleNav: 'Toggle navigation',
  tradingName: 'Trading name',
  tradingNamePlaceholder: 'Type Trading name here',
  uptimeAndStatus: 'Uptime and Status',
  viewPlan: 'View Plan',
  viewPlans: 'View Plans',
  virtualOffice: 'Virtual Office',
  virtualOfficeCollect: 'Virtual Office Collect',
  virtualOfficeForward: 'Virtual Office Forward',
  virtualOfficeLondon: 'Virtual Office London',
  virtualOfficePhone: 'Virtual Office Phone',
  virtualOfficePlans: 'Virtual Office Plans',
  virtualOfficeScanLite: 'Virtual Office Scan Lite',
  virtualOfficeScanPro: 'Virtual Office Scan Pro',
  virtualPhone: 'Virtual Phone',
  weAcceptMajorCards:
    'We accept major international debit or credit cards, including Visa, MasterCard, PayPal and American Express.',
  weAlsoOffer: 'We also offer:',
  weHaveHelped: 'We’ve already helped thousands of companies.',
  whatDoesYourCompanyDo: 'What does your company do?',
  whatDoesYourCompanyDoPlaceholder:
    'Ex. London based design agency who specialises in user interface design.',
  whereShallWeSendThePost: 'Where shall we send the post?',
  whereShallWeSendThePostDescription:
    'Please enter the address below of where we should send your post.',
}
const stringRecords: Record<string, Partial<LanguageContent>> = {
  en: defaultContent,
  es: blobbyStrings(defaultContent),
}
export { stringRecords, getStrings, setStrings }
