import { useEffect, useMemo, useRef, useState } from 'react'
import { TAddon, TDuration, TPlanSummary } from './cms-types'
import { Project } from './project'
import { paymentStrings } from './strings/payment-strings'
import { getPriceIds } from 'components/util/getPriceIds' // we need this to make JSX compile
import useLocalStorageState from 'use-local-storage-state'

type Coupon = {
  code: string
  discountedTotal: number //New basket total including coupon
  discountedVAT: number // Coupon discounted VAT
  discountAmount: number // Coupon discounted amount
  discountDescription: string // Coupon description
}
export let coupon: Coupon | null
export default function (
  plan: TPlanSummary | null,
  allAddons: TAddon[] | null,
  duration: TDuration,
  addons: string[],
) {
  const [discount, setDiscount] = useLocalStorageState<number | null>(
    'discount',
    { defaultValue: null, storageSync: false },
  )
  const [activeCoupon, setActiveCoupon] = useLocalStorageState<string>(
    'activeCoupon',
    { defaultValue: '', storageSync: false },
  )
  const [couponLoading, setCouponLoading] = useState(false)
  const [couponError, setCouponError] = useState('')
  const [discountedTotal, setDiscountedTotal] = useLocalStorageState<
    number | null
  >('discountedTotal', { defaultValue: null, storageSync: false })
  const [discountedVAT, setDiscountedVAT] = useLocalStorageState<number | null>(
    'discountedVAT',
    { defaultValue: null, storageSync: false },
  )
  const [_, setActiveCouponInput] = useLocalStorageState<string>(
    'couponInput',
    { defaultValue: '', storageSync: false },
  )
  const [discountDescription, setDiscountDescription] =
    useLocalStorageState<string>('discountDescription', {
      defaultValue: '',
      storageSync: false,
    })
  const intervalRef = useRef()

  const setInitialCoupon = useRef(false)
  if (!setInitialCoupon.current) {
    setInitialCoupon.current = true
    if (discount !== null) {
      coupon = {
        code: activeCoupon,
        discountAmount: discount,
        discountDescription: discountDescription,
        discountedTotal: discountedTotal,
        discountedVAT: discountedVAT,
      }
    }
  }

  useEffect(() => {
    if (intervalRef?.current) {
      clearInterval(intervalRef?.current)
    }
    const interval = setInterval(() => {
      if (coupon?.code !== activeCoupon) {
        if (!coupon) {
          setActiveCoupon('')
          setDiscount(null)
          setDiscountedVAT(null)
          setDiscountedTotal(null)
          setDiscountDescription('')
        } else {
          setActiveCoupon(coupon?.code)
          setDiscount(coupon?.discountAmount)
          setDiscountedVAT(coupon?.discountedVAT)
          setDiscountedTotal(coupon?.discountedTotal)
          setDiscountDescription(coupon?.discountDescription)
        }
      }
    }, 500)
    return () => clearInterval(interval)
  }, [activeCoupon])
  const planPrice = plan
    ? duration === 'month'
      ? plan.pricing.month_price
      : plan.pricing.year_price
    : 0

  const addonPrice = useMemo(() => {
    let price = 0
    if (!allAddons) return 0
    addons.map((addon) => {
      const addonObj = allAddons.find((v) => v.slug === addon)
      const addonPrice = addonObj
        ? duration === 'month'
          ? addonObj.pricing.month_price
          : addonObj.pricing.year_price
        : 0

      price += addonPrice
    })
    return price
  }, [addons, allAddons, duration])

  const originalVAT = Math.ceil((addonPrice + planPrice) * 0.2)
  const vatPrice = Math.ceil(
    typeof discountedVAT === 'number' ? discountedVAT : originalVAT,
  )
  const totalWithoutDiscount = Math.ceil(planPrice + addonPrice + originalVAT)
  const totalPrice = Math.ceil(
    typeof discountedTotal === 'number'
      ? discountedTotal
      : totalWithoutDiscount,
  )

  const clearCoupon = () => {
    setActiveCouponInput('')
    setCouponError('')
    coupon = null
  }

  const checkCoupon = (code: string) => {
    setCouponError('')
    coupon = null // clear erros and previously applied coupon
    setCouponLoading(true)
    const ids = getPriceIds(
      [plan!],
      allAddons,
      plan?.slug || null,
      addons,
      duration,
    )
    fetch(`${Project.api}checkout/calculate`, {
      body: JSON.stringify({
        addons: ids?.addons.filter((v) => !!v),
        product_id: ids?.product_id,
        voucher: code,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then((res) => {
        return res.json()
      })
      .then((res) => {
        setCouponLoading(false)
        if (res.discount_amount) {
          coupon = {
            code,
            discountAmount: res.discount_amount,
            discountDescription: res.discount_description,
            discountedTotal: res.total,
            discountedVAT: res.tax,
          }
          setCouponError('')
        } else {
          throw ''
        }
      })
      .catch(() => {
        setDiscount(0)
        setCouponLoading(false)
        setCouponError(`${paymentStrings.couponError}`)
      })
  }

  return {
    activeCoupon,
    addonPrice,
    checkCoupon,
    clearCoupon,
    couponError,
    couponLoading,
    discount,
    discountDescription,
    originalVAT,
    planPrice,
    totalPrice,
    totalWithoutDiscount,
    vatPrice,
  }
}
